
import './App.css';
import React, { createContext, useContext, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Home from "./Pages/Home";
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import QuantumAI from './Pages/QuantumAI';
import Partners from './Pages/Partners';
import Ourips from './Pages/Ourips';
import Resources from './Pages/Resources';
import Services from './Pages/Services'
import Marketfit from './Pages/Marketfit';
import QSleeve from './Pages/ProductPages/QSleeve';
import PageNotFound from './Pages/PageNotFound'; 
import LabAI from './Pages/ProductPages/LABAI';  
import HealMed from './Pages/ProductPages/HealMed';
import QubitIQ from './Pages/ProductPages/QubitIQ';
import QMag from './Pages/ProductPages/QMag';
import QMemory from './Pages/ProductPages/QMemory';
import QFolioPro from './Pages/ProductPages/QFoliopro';
import QKD from './Pages/ProductPages/QKD';
import ClAIms from './Pages/ProductPages/ClAIms';
import ChecQ from './Pages/ProductPages/ChecQ';

// Authentication Context
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Replace with real auth logic
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Define protected paths
  const protectedPaths = [
    "/Company/Aboutus",
    "/Company/Contactus",
    "/Products/Quantum-AI",
    "/Products/Marketfit",
    "/Services",
    "/Partners",
    "/Ourip",
    "/Resources",
    "/Products/Qsleeve",
  ];

  // Check if the current path is protected and if the user is authenticated
  if (protectedPaths.includes(location.pathname) && !isAuthenticated) {
    return <Navigate to="/404" />;
  }

  return children;
};

// Handle static paths for /static/css, /static/media, and /static/js to redirect to 404
const StaticRedirect = () => {
  const location = useLocation();
  const blockedPaths = ['https://qulabs.ai/static/css', 'https://qulabs.ai/static/media', 'https://qulabs.ai/static/js'];

  // If the path matches any of the blocked paths, redirect to 404
  if (blockedPaths.some(path => location.pathname.startsWith(path))) {
    return <Navigate to="/404" />;
  }

  return null;
};



export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <StaticRedirect /> {/* Check for blocked static paths */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/Company/Aboutus"
            element={
              <ProtectedRoute>
                <Aboutus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Company/Contactus"
            element={
              <ProtectedRoute>
                <Contactus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Products/Quantum-AI"
            element={
              <ProtectedRoute>
                <QuantumAI />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Products/Marketfit"
            element={
              <ProtectedRoute>
                <Marketfit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Services"
            element={
              <ProtectedRoute>
                <Services/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/Partners"
            element={
              <ProtectedRoute>
                <Partners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Ourip"
            element={
              <ProtectedRoute>
                <Ourips />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Resources"
            element={
              <ProtectedRoute>
                <Resources />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Products/Qsleeve"
            element={
              <ProtectedRoute>
                <QSleeve  />
              </ProtectedRoute>
            }
          />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} /> {/* Catch-all for unmatched routes */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

